import React , {useState} from 'react';
import AWS from 'aws-sdk'


const S3_BUCKET = process.env.REACT_APP_bucket_name;
const REGION = process.env.REACT_APP_S3_REGION;
const ACCESS_KEY =process.env.REACT_APP_AWS_ACCESS_KEY;
const SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_KEY;


AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
})

export default function Upload2() {

    const [progress , setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [message,setMessage]=useState("")

  const handleFileInput = (e) => {
      setSelectedFile(e.target.files[0]);
  }

  const uploadFile = (file) => {

      const params = {
          Body: file,
          Bucket: S3_BUCKET,
          Key: "user2/"+file.lastModifiedDate+"_"+file.name
      };

      myBucket.putObject(params)
          .on('httpUploadProgress', (evt) => {
              setProgress(Math.round((evt.loaded / evt.total) * 100))
              setMessage("Successfully Uploaded")
          })
          .send((err) => {
              if (err) console.log(err)
          })
  }


  return (
    <>
      <div id="header-wrapper">
        <header id="header" className="5grid-layout">
          <div className="row">
            <div className="12u">
              <h1>
                <a
                  href="https://www.abexperiment.com/"
                  className="mobileUI-site-name"
                >
                  Upload Folder/Files to convert into Website
                </a>
              </h1>

              <nav className="mobileUI-site-nav">
                <a href="https://www.abexperiment.com/">Home</a>
                <a href="https://www.abexperiment.com/about_us/">About us</a>
              </nav>
            </div>
          </div>
        </header>

        <div id="banner">
					<div class="5grid-layout">
						<div class="row">
							<div class="6u">

							</div>
							<div class="6u">
							
							</div>
						</div>
					</div>
				</div>
			
      </div>

      <div id="content-wrapper">
        <div id="content">
          <div className="5grid-layout">
            <div className="row">
              <div className="9u">
                <section>
                  <header>
                    <h2>Kreate</h2>
                    <h3>Add experience to your content</h3>
                  </header>
                  <div>
                      <div>File Upload Progress is {progress}%</div>
                      <input type="file"  onChange={handleFileInput}/>
                      <button class="button-big" onClick={() => uploadFile(selectedFile)}> Upload</button>
                      <div>{message}</div>
                  </div>
                </section>
              </div>
              <div className="3u">
                <section>
                  
                </section>
                <section>
                  <header>
                    <h2>Content location</h2>
                  </header>
                  <p>
                    File(s) can be sync from local computer, AWS S3, Azure blob, GCP bucket or on-prem
                    <br/>Content owner focus on creating content and it automatically convert to web page(s).
                  </p>
                  <p>
                    Permission associated with data dictate permission of web page/ visualization created.
                  </p>
                </section>
                <section>
                  <header>
                    <h2>Contact us</h2>
                  </header>
                  <p>
                    Our solution scale to miilions of web pages.
                    <br/>To manage large portal, intranet sites contact us at scale@ataknobs.com
                  </p>
                  <ul className="link-list">
                    <li>
                      <a href="https://www.dataknobs.com/about-dataknobs/contact.html">Contact us</a>
                    </li>
                  </ul>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="footer-wrapper">
        <footer id="footer" className="5grid-layout">
          <div className="row">
            <div className="8u">
              <section>
                <h2>AB testing related topics</h2>
                <div className="5grid">
                  <div className="row">
                    <div className="3u">
                      <ul className="link-list last-child">
                        <li>
                          <a href="/#">Travel</a>
                        </li>
                      </ul>
                    </div>
                    <div className="3u">
                      <ul className="link-list last-child">
                        <li>
                          <a href="/#">Small businesses</a>
                        </li>
                      </ul>
                    </div>
                    <div className="3u">
                      <ul className="link-list last-child">
                        <li>
                          <a href="/#">Medium traffic</a>
                        </li>
                      </ul>
                    </div>
                    <div className="3u">
                      <ul className="link-list last-child">
                        <li>
                          <a href="/#">Large traffic websites</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="4u">
              <section>
                <h2>Our Motto</h2>
                <p>
                  At AB Experiment we excel in our field of experimentation. We
                  integrate A/B testing with app development.
                </p>
              </section>
            </div>
          </div>
        </footer>
      </div>

      <div id="copyright">
        &copy; AB Experiment. All rights reserved. | Design:{" "}
        <a href="https://www.abexperiment.com/">AB Experiment</a>
      </div>
    </>
  );
}
