
import './App.css';
 
// import Uploady from "@rpldy/uploady";
// import UploadDropZone from "@rpldy/upload-drop-zone";
// import { yellow } from '@mui/material/colors';
// import UploadToS3WithNativeSdk from './pages/upload';
import Upload2 from './pages/upload2';
function App() {
 
  return (
    <>

      <Upload2/>
 
    </>
  );
}


export default App;
